import React, {Component} from 'react';
import MovementDefinition from '../lib/MovementDefinition.js';

export default class UIMovementDefinition extends Component {
    constructor(props) {
      super(props);
      this.changeCallback = props.changeCallback;
      console.log(props.movementDefinition);
      this.state = {
        movementDefinition: props.movementDefinition
      };
    }
  
    changeDropdown = e => {
      let newMovementDef = new MovementDefinition(
        this.state.movementDefinition.startRow,
        this.state.movementDefinition.startCol,
        this.state.movementDefinition.axis,
        this.state.movementDefinition.horzDirection,
        this.state.movementDefinition.vertDirection
      )
      newMovementDef[e.target.name] = e.target.value;
      console.log('newMovementDef', newMovementDef);
      this.setState({
        movementDefinition: newMovementDef
      });
  
      // this.setState({
      //   [e.target.name]: e.target.value,
      // });
    }
  
    componentDidUpdate(prevProps, prevState) {
      console.log('componentDidUpdate', this.state);
      if (prevState !== this.state) {
        this.changeCallback(this.state.movementDefinition);
      }
    }
  
    render() {
      return (
        <div className='movementPattern'>
          Use {this.props.type} wells starting at&nbsp;
          <select name='startRow' value={this.state.movementDefinition.startRow} onChange={this.changeDropdown.bind(this)}><option value='A'>A</option><option value='B'>B</option></select>
          <select name='startCol' value={this.state.movementDefinition.startCol} onChange={this.changeDropdown.bind(this)}><option value='1'>1</option><option value='2'>2</option></select>
          <br></br>
          moving by&nbsp;
          <select name='axis' value={this.state.movementDefinition.axis} onChange={this.changeDropdown.bind(this)}><option value='by-row'>by row</option><option value='by-col'>by column</option></select>
          <select name='horzDirection' value={this.state.movementDefinition.horzDirection} onChange={this.changeDropdown.bind(this)}><option value='ltr'>left to right</option><option value='rtl'>right to left</option></select> and&nbsp;
          <select name='vertDirection'value={this.state.movementDefinition.vertDirection} onChange={this.changeDropdown.bind(this)}><option value='ttb'>top to bottom</option><option value='btt'>bottom to top</option></select>
        </div>
      );
    }
  }