
// When you create a well you can set the `content`
// You can set it to anything you want. A good idea might be 
// to set it to a hash, where you can set any keys and values that you want.
// For example:
// content = {
//   antigen: 'HIV',
//   reagent: { name: 'p24', concentration: '2mg/ml' }
// }
//
// The well also has `flags`. These are used internally to determine things that
// the system knows about. For example: Is the well empty? Should it be skipped/ignored?
export default class Well {
  constructor(content = null, rowIndex, colIndex) {
    this.content = content;
    this.rowIndex = rowIndex;
    this.colIndex = colIndex;
    this.flags = {};

    let id = localStorage.getItem("well_id");
    if(id == null) {
      id = 0;
    }
    else {
      id++;
    }
    localStorage.setItem("well_id", id);
    this.id = id;
  }

  set(content) {
    this.content = content;
  }

  flag(label, value = true) {
    if(value)
      console.log('flagging well ', this.id);
    this.flags[label] = value;
  }

  unflag(label) {
    delete this.flags[label];
  }

  flaggedWith(label) {
    return (label in this.flags);
  }

  toggleFlag(label) {
    if(this.hasFlag(label)) {
      this.unflag(label);
    }
    else {
      this.flag(label);
    }
  }

  hasFlag(label, value = true) {
    return (this.flags[label] === value);
  }

  // Returns the names of all flags that aren't set to false or null
  getFlags() {
    let result = [];
    for(let label in this.flags) {
      if(this.flags[label] !== false && this.flags[label] !== null) {
        result.push(label);
      }
    }
    return result;
  }
}
