import React, {Component} from 'react';
import RepeatDefinition from '../lib/RepeatDefinition.js';

export default class UIRepeatDefinition extends Component {
    constructor(props) {
      super(props);
      this.changeCallback = props.changeCallback;
      console.log(props.repeatDefinition);
      this.state = {
        repeatDefinition: props.repeatDefinition
      };
    }
  
    changeDropdown = e => {
      let newDef = new RepeatDefinition({
        repeat: this.state.repeatDefinition.repeat,
        skip: this.state.repeatDefinition.skip
      });
      newDef[e.target.name] = e.target.value;
      this.setState({
        repeatDefinition: newDef
      });
    }
  
    componentDidUpdate(prevProps, prevState) {
      console.log('componentDidUpdate', this.state);
      if (prevState !== this.state) {
        this.changeCallback(this.state.repeatDefinition);
      }
    }
  
    render() {
      return (
        <div className='repeatDefinition'>
          <div className='labels'>
            <label>Repeat:</label>
            <label>Skip:</label>
          </div>
          <div className='fields'>
            <input type='number' min='1' max='100' name='repeat' value={this.state.repeatDefinition.repeat} onChange={this.changeDropdown.bind(this)} />
            <input type='number' min='0' name='skip' value={this.state.repeatDefinition.skip} onChange={this.changeDropdown.bind(this)} />
          </div>
        </div>
      );
    }
  }