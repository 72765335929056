export default class RTL_TTB_Iterator {
  constructor(plateSet, options = { axis: 'row', startRow: null, startCol: null, allowCreate: null }) {
    this.plateSet = plateSet;
    this.startRow = options.startRow || 0;
    this.startCol = options.startCol || plateSet.getPlate(0).width() - 1;
    this.row = this.startRow;
    this.col = this.startCol;
    this.allowCreate = options.allowCreate || false;
    this.currentPlateIndex = 0;

    if(options.axis === 'row') {
      this.primaryIndex = 'col';
      this.secondaryIndex = 'row';
      this.primaryLimit = 'width';
      this.secondaryLimit = 'height';
    }
    else if(options.axis === 'col') {
      this.primaryIndex = 'row';
      this.secondaryIndex = 'col';
      this.primaryLimit = 'height';
      this.secondaryLimit = 'width';
    }
    else {
      throw new Error(`Unexpected axis option: ${options.axis}`);
    }
  }

  currentPlate() {
    return this.plateSet.getPlate(this.currentPlateIndex);
  }

  next() {
    let curr = this.current();
    this.increment();

    while(curr.hasFlag('skip', true) && (this.hasMore() || this.allowCreate)) {
      curr = this.current();
      this.increment();
    }

    if(curr.hasFlag('skip', true) && (!this.allowCreate)) {
      return null;
    }

    return curr;
  }

  current() {
    try{
      if(this.currentPlateIndex >= this.plateSet.size() && this.allowCreate) {
        this.plateSet.createPlate();
      }
    } catch (e) {
      console.log('111', e);
      alert('111');
    }

    let well; 
    try {
      well = this.currentPlate().get(this.row, this.col);
    } catch(e) {
      debugger;
    }
    return well;
  }

  // This doesn't really support switching rows to cols
  increment() {
    this[this.primaryIndex]--;

    if(this[this.primaryIndex] < 0) {
        this[this.primaryIndex] = this.startCol; // what if it's not col?
        this[this.secondaryIndex]++;
    }

    if(this[this.secondaryIndex] >= this.currentPlate()[this.secondaryLimit]()) {
        this.currentPlateIndex++;
        this[this.secondaryIndex] = 0;
    }
  }

  hasMore() {
    if(this.currentPlate() === undefined || this.col < 0 || this.row >= this.currentPlate().height()) {
        return false;
    }

    return true;
  }
}
