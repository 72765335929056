// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseInit = () => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCLYH9eUQPPVnQUlelXBXbY1PacLqs-MFA",
    authDomain: "plates-d1453.firebaseapp.com",
    projectId: "plates-d1453",
    storageBucket: "plates-d1453.appspot.com",
    messagingSenderId: "266129268726",
    appId: "1:266129268726:web:3c2fe0693db2ef9bbcf1d7",
    measurementId: "G-54TJQLR56K"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}

export default firebaseInit;