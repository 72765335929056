import React from 'react';
import Color from '../lib/Color.js';
import Alphabet from '../lib/Alphabet.js';
import UIWell from './UIWell.js';

export default class UIPlateSet extends React.Component {
  constructor(props) {
    super(props);
    this.rows = props.rows;
    this.cols = props.cols;
    this.getColor = props.getColor;

    this.state = {
      plateSet: props.plateSet
    };
  }

  render() {
    let plateDivs = this.state.plateSet.map((plate) => {
      return this.renderPlate(plate);
    });
    return (<div className="plateSet">{plateDivs}</div>);
  }

  foregroundColor(bgColor) {
    return Color.hslIsDark(bgColor) ? '#FFFFFF' : '#000000';
  }

  clickedColumnHeader(plate, columnIndex) {
    this.state.plateSet.selectColumn(columnIndex);
  }

  clickedRowHeader(plate, rowIndex) {
    this.state.plateSet.selectRow(rowIndex);
  }

  clickedSelectAll(plate) {
    this.state.plateSet.toggleAll();
  }

  renderPlate(plate) {
    const plid = "pl-" + plate.id;
    const rowDivs = [];
    let wellDivs = [];
    const headerCells = [];

    headerCells.push(<UIWell key={plid + "blank-corner"} name="" onClick={(e) => { this.clickedSelectAll(plate) }} cssClass="spot"></UIWell>);
    for(let headerCol = 0; headerCol < this.cols; headerCol++) {
      headerCells.push(<UIWell key={plid+"col-header-"+headerCol} name={headerCol+1} onClick={(e) => { this.clickedColumnHeader(plate, headerCol) }} cssClass="spot"></UIWell>);
    }

    rowDivs.push(
      <div key="header-row" className="row header">{headerCells}</div>
    );

    for(let rowIndex = 0; rowIndex < this.rows; rowIndex++) {
      wellDivs = [];
      // The left most item of each row is a row header
      wellDivs.push(<UIWell key={plid+"row-header-"+rowIndex} row={rowIndex+1} col="0" name={Alphabet.get(rowIndex)} onClick={(e) => { this.clickedRowHeader(plate, rowIndex) }} cssClass="spot"></UIWell>);

      // Add wells to the row
      for(let colIndex = 0; colIndex < this.cols; colIndex++) {
        let name = '';
        let well = null;
        try {
          well = plate.get(rowIndex, colIndex);
          name = well.content.name;
        } catch(e) {
          // console.log("Possible Error: Requested a position that doesn't exist: (row, col)", rowIndex, colIndex);
          // console.log(e);
        }

        let colorCss = {};
        if(!well.hasFlag('skip')) {
          let bgColor = this.getColor(name);
          colorCss = { backgroundColor: bgColor, color: this.foregroundColor(bgColor) };
        }
        wellDivs.push(<UIWell key={plid+"well-"+rowIndex+"x"+colIndex} well={well} name={name} cssClass="well" wellStyle={colorCss} />);
      }
      rowDivs.push(
        <div key={plid + "row-" + rowDivs.length} className="row">{wellDivs}</div>
      );
    }

    return (
      <div key={plid} className="plate" data-id={plid}>{rowDivs}</div>
    );
  }
}