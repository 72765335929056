import LTR_TTB_Iterator from './LTR_TTB_Iterator.js';
import RTL_TTB_Iterator from './RTL_TTB_Iterator.js';

export default class IteratorFactory {

  /* Movement Definition:
   * {
        this.startRow = startRow;
        this.startCol = startCol;
        this.axis = axis;
        this.horzDirection = horzDirection;
        this.vertDirection = vertDirection;
    }
  */
  static fromMovementDefinition(movementDefinition, plateSet, options = {}) {
    if(movementDefinition.axis === 'by-row') {
      options.axis = 'row';
    }
    if(movementDefinition.axis === 'by-col') {
      options.axis = 'col';
    }

    if(movementDefinition.horzDirection === 'ltr') {
      return new LTR_TTB_Iterator(plateSet, options);
    }
    else if(movementDefinition.horzDirection === 'rtl') {
      return new RTL_TTB_Iterator(plateSet, options);
    }
  }
}