import Range from './Range.js'
import ColorShader from './ColorShader.js'

export default class Color {
  static get(howMany) {
    let colors = [];
    while(colors.length < howMany) {
      colors = colors.concat(this.getABunchOfColorsInHSL(0, 30, 82, 56));
      colors = colors.concat(this.getABunchOfColorsInHSL(10, 30, 92, 87));
      colors = colors.concat(this.getABunchOfColorsInHSL(15, 30, 64, 35));
      colors = colors.concat(this.getABunchOfColorsInHSL(23, 30, 23, 61));
    }
    return colors;
  }

  // This option has a set of hard coded colors.
  // If you want more colors than this you can add more to the list
  // or use ColorShader.lighten() or ColorShader.darken()
  static all() {
    return ['#C84E00', '#E89923', '#FFD960', '#A1B70D', '#339898', '#1D6363', '#005587', '#0577B1', '#993399', '#E2E6ED', '#F3F2F1', '#FCF7E5', '#988675', '#DAD0C6', '#262626', '#666666', '#B5B5B5', '#E5E5E5'];
  }

  static getABunchOfColorsInHSL(start = 0, inc = 30, saturation = 82, lightness = 56, alpha = 1) {
    let colors = [];
    for(let deg = 0; deg < 360; deg += inc) {
      colors.push(`hsl(${deg}deg ${saturation}% ${lightness}% / ${alpha})`);
    }
    return colors;
  }

  // This option will create new colors based on a given index in a list.
  static createOne(index) {
    const hue = index * 137.508; // use golden angle approximation
    return `hsl(${hue},50%,75%)`;
  }

  static hslIsDark(color) {
    return false;
    console.log('isDark', color);
    color = `${color}`;

    // Check the format of the color, HEX or RGB?
    let matches = color.match(/^hsl\(\d+deg (\d+)% (\d+)%.*?/);
    if (matches) {
      console.log('color', color, matches[1]);

      let saturation = parseInt(matches[1], 10);
      let luminosity = parseInt(matches[2], 10);

      if(luminosity < 42) {
        console.log('isDark')
        return true;
      }
      else {
        console.log('is light');
        return false;
      }
    }

    return false;
  }
}