import React, {Component} from 'react';
import MovementDefinition from '../lib/MovementDefinition.js';
import RepeatDefinition from '../lib/RepeatDefinition.js';
import Plate from '../lib/Plate.js';
import PlateSet from '../lib/PlateSet.js';
import ContainerMap from '../lib/ContainerMap.js';
import Color from '../lib/Color.js';
import IteratorFactory from '../lib/PlateIterators/IteratorFactory.js';
import UIMovementDefinition from './UIMovementDefinition.js';
import UIRepeatDefinition from './UIRepeatDefinition.js';
import UIPlateSet from './UIPlateSet.js';

function dummyContents(count = 96) {
  let contents = [];
  for(let i = 0; i < count; i ++) {
    contents.push({
        name: `${i+1}` // "Item ${x}"
    });
  }
  return contents;
}

function emptyContents(count = 96) {
  let contents = [];
  for(let i = 0; i < count; i ++) {
  contents.push({
    name: ''
  });
}
return contents;
}

export default class UIPlateMapTemplate extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sourcePlateSet: new PlateSet({ plates: new Plate(dummyContents(96), {name: 'srcplate1'}) }),
        sourceMovementDef: new MovementDefinition(),
        destPlateSet: new PlateSet({ plates: new Plate(emptyContents(96), {name: 'destplate1'}) }),
        destMovementDef: new MovementDefinition(),
        repeatDefinition: new RepeatDefinition()
      }
      this.colorMap = {};
    }
  
    runMapping() {
      let sourceIterator = IteratorFactory.fromMovementDefinition(
        this.state.sourceMovementDef,
        this.state.sourcePlateSet
      );

      this.state.destPlateSet.resetPlates();

      let destIterator = IteratorFactory.fromMovementDefinition(
        this.state.destMovementDef,
        this.state.destPlateSet,
        { allowCreate: true }
      );
  
      let map = new ContainerMap(sourceIterator, destIterator, this.state.repeatDefinition);
      let resultPlateSet = map.map();
  
      this.setState({ destPlateSet: resultPlateSet });
    }
  
    getColor(name) {
      return this.colorMap[name];
    }
  
    generateColorMap() {
      let plate = this.state.sourcePlateSet.getPlate(0);
      let names = plate.getUniqueAttributes('name');
      let colors = Color.get(names.length);
      names.forEach((name, index) => {
        let safeIndex = index % colors.length;
        this.colorMap[name] = colors[safeIndex];
      });
    }
  
    sourceMovementDefinitionChanged(movementDefinition) {
      console.log('source movementDef is changing', movementDefinition);
      this.setState({
        sourceMovementDef: movementDefinition
      });
    }
  
    destMovementDefinitionChanged(movementDefinition) {
      console.log('dest movementDef is changing', movementDefinition);
      this.setState({
        destMovementDef: movementDefinition
      });
    }
  
    repeatDefinitionChanged(repeatDefinition) {
      console.log('repeatDefinition is changing', repeatDefinition);
      this.setState({
        repeatDefinition: repeatDefinition
      });
    }
  
    componentDidUpdate(prevProps, prevState) {
      console.log('Template componentDidUpdate', this.state);
      if (
        (prevState.sourceMovementDef !== this.state.sourceMovementDef) || 
          (prevState.destMovementDef !== this.state.destMovementDef) ||
          (prevState.repeatDefinition !== this.state.repeatDefinition)
        ) {
        this.runMapping();
      }
    }
  
    clickHandler(e) {
      setTimeout(() => {this.runMapping();}, 10);
      // this.runMapping();
    }

    render() {
      this.generateColorMap();
  
      return(
        <div className="plateMapTemplate" onClick={this.clickHandler.bind(this)}>
          <div className="PlateDefinition">
            <UIMovementDefinition type="source" changeCallback={this.sourceMovementDefinitionChanged.bind(this)} movementDefinition={this.state.sourceMovementDef} /> 
            <UIPlateSet cols="12" rows="8" key="source" plateSet={this.state.sourcePlateSet} getColor={this.getColor.bind(this)}></UIPlateSet>
          </div>
          <UIRepeatDefinition changeCallback={this.repeatDefinitionChanged.bind(this)} repeatDefinition={this.state.repeatDefinition} />
          <div className="PlateDefinition" onClick={this.clickHandler.bind(this)}>
            <UIMovementDefinition type="destination" changeCallback={this.destMovementDefinitionChanged.bind(this)} movementDefinition={this.state.destMovementDef} />
            <UIPlateSet cols="12" rows="8" key="destination" plateSet={this.state.destPlateSet} getColor={this.getColor.bind(this)}></UIPlateSet>
          </div>
        </div>
      );
    }
  }