import Well from './Well.js';
import Alphabet from './Alphabet.js';
import { nanoid } from 'nanoid';

export default class Plate {
    // contents: contents,
    // options: {
    //     rows: 8,
    //     cols: 12,
    //     rowLabels: ['A','B','C','D','E','F','G','H'],
    //     colLabels: ['1','2','3','4','5','6','7','8','9','10','11','12'],
    //
    // }
    constructor(contents = [], options = { rows: null, cols: null, rowLabels: null, colLabels: null, name: null }) {
      this.name = options.name;
      this.rows = options.rows || 8;
      this.cols = options.cols || 12;
      this.rowLabels = options.rowLabels || Alphabet.slice(0, 7);
      this.colLabels = options.colLabels || ['1','2','3','4','5','6','7','8','9','10','11','12'] ;
      this.wells = [];
      this.id = nanoid();

      // Create a matrix of Well objects.
      let i = 0;
      for(let row = 0; row < this.rows; row++) {
        this.wells[row] = [];
        for(let col = 0; col < this.cols; col++) {
          let content = contents.length > i ? contents[i] : null;
          this.wells[row][col] = new Well(content, row, col);
          i++;
        }
      }
    }

    width() {
      return this.cols;
    }

    height() {
      return this.rows;
    }

    set(row, col, content) {
      let well = this.get(row, col);
      well.set(content);
    }

    // Get the well at the given location.
    // e.g. get(0, 0)
    // e.g. get('A', '1')
    // If you use integers they should be row and column indexes. That is, they 
    // should be zero-based. Which means for a 12 column plate you would use 0 - 11
    // If you use strings, they should be row and column labels. So then you would
    // use '1' - '12'.
    get(row, col) {
      if(typeof row === "string") {
        row = this.getRowIndex(row);
      }
      if(typeof col === "string") {
        col = this.getColIndex(col);
      }

      if(row === undefined || row >= this.wells.length) {
        throw new Error(`Requested row with index ${row}, but the plate's largest row index is ${this.wells.length - 1}`);
      }
      if(col >= this.wells[row].length) {
        throw new Error(`Requested column with index ${col}, but the plate's largest columh index is ${this.wells[row].length - 1}`);
      }

      return this.wells[row][col];
    }

    // Converts "A" to 0
    getRowIndex(rowName) {
      return this.rowLabels.indexOf(rowName);
    }

    // Converts '12' to 11
    getColIndex(colName) {
      return this.colLabels.indexOf(colName);
    }

    selectColumn(colIndex) {
      // not implemented
    }

    // You give it an attribute, and it gives you a unique set of values for
    // that attribute in the plate.
    getUniqueAttributes(attr = 'name') {
      let values = [];
      this.each((well) => {
        values.push(well.content[attr]);
      });
      return Plate.unique(values);
    }

    // This should be moved out to a helper somewhere.
    static unique(array) {
      return [...new Set(array)];
    }

    each(func) {
      for(let rowIndex = 0; rowIndex < this.rows; rowIndex++) {
        for(let colIndex = 0; colIndex < this.cols; colIndex++) {
          func.call(this, this.wells[rowIndex][colIndex]);
        }
      }
    }

    print() {
      let rows = this.wells.map((row) => {
        let cells = row.map((well) => {
          return well.content.name;
        });
        return cells.join(" | ");
      });
      console.log(`======= ${this.name} =======\n${rows.join("\n")}`);
    }

    printRow(rowIndex) {}
}
