import RepeatDefinition from './RepeatDefinition.js'

export default class ContainerMap {

    constructor(sourceIterator, destIterator, repeatDef = null) {
      this.sourceIterator = sourceIterator;
      this.destIterator = destIterator;
      this.repeatDef = repeatDef || new RepeatDefinition();
    }

    map() {
      let repeatWells = []
      while(this.sourceIterator.hasMore()) {
        let sourceWell = this.sourceIterator.next();

        if(sourceWell != null) {
          let destWell = this.destIterator.next();

          // console.log('destWell', destWell);
          this.copyWell(sourceWell, destWell);

          // We reached the number we're supposed to skip before repeating
          // So we should start repeating now.
          // And do it the defined number of repeat times
          // console.log('final repeat cnt', this.repeatDef.repeat);
          if(this.repeatDef.repeat > 1) {
            repeatWells.push(sourceWell);
            if(repeatWells.length > this.repeatDef.skip) {
              for(let repeatIndex = 0; repeatIndex < this.repeatDef.repeat - 1; repeatIndex++) {
                for(let wellIndex = 0; wellIndex < repeatWells.length; wellIndex++) {
                  sourceWell = repeatWells[wellIndex];
                  let destWell = this.destIterator.next();
                  this.copyWell(sourceWell, destWell);
                }
              }
              repeatWells = [];
            }
            // TODO: Need to change the destination plate set so that it can
            // Add new plates when it runs out of space.
          }
        } 
      }

      return this.destIterator.plateSet;
    }

    copyWell(sourceWell, destWell) {
      destWell.content = sourceWell.content;
    }
}
