// Left-to-right, and Top-to-bottom
export default class LTR_TTB_Iterator {
  constructor(plateSet, options = { axis: 'row', startRow: null, startCol: null, allowCreate: null }) {
    this.plateSet = plateSet;
    this.row = options.startRow || 0;
    this.col = options.startCol || 0;
    this.allowCreate = options.allowCreate || false;
    this.currentPlateIndex = 0;

    if(options.axis === 'row') {
      this.primaryIndex = 'col';
      this.secondaryIndex = 'row';
      this.primaryLimit = 'width';
      this.secondaryLimit = 'height';
    }
    else if(options.axis === 'col') {
      this.primaryIndex = 'row';
      this.secondaryIndex = 'col';
      this.primaryLimit = 'height';
      this.secondaryLimit = 'width';
    }
    else {
      throw new Error(`Unexpected axis option: ${options.axis}`);
    }
  }

  currentPlate() {
    return this.plateSet.getPlate(this.currentPlateIndex);
  }

  next() {
    let curr = this.current();
    this.increment();

    while(curr.hasFlag('skip', true) && (this.hasMore() || this.allowCreate)) {
      curr = this.current();
      this.increment();
    }

    if(curr.hasFlag('skip', true) && (!this.allowCreate)) {
      return null;
    }

    return curr;
  }

  current() {
    if(this.currentPlateIndex >= this.plateSet.size() && this.allowCreate) {
      this.plateSet.createPlate();
    }
  
    return this.currentPlate().get(this.row, this.col);
  }

  increment() {
    this[this.primaryIndex]++;

    if(this[this.primaryIndex] >= this.currentPlate()[this.primaryLimit]()) {
        this[this.primaryIndex] = 0;
        this[this.secondaryIndex]++;
    }

    if(this[this.secondaryIndex] >= this.currentPlate()[this.secondaryLimit]()) {
        this.currentPlateIndex++;
        this[this.secondaryIndex] = 0;
    }
  }

  hasMore() {
    if(this.currentPlate() === undefined || this.col >= this.currentPlate().width() || this.row >= this.currentPlate().height()) {
        return false;
    }

    return true;
  }
}
