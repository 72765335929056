import React from 'react';
import * as Toolbar from '@radix-ui/react-toolbar';
import {
  TextAlignLeftIcon,
  TextAlignCenterIcon,
  TextAlignRightIcon,
  MoveIcon,
  GroupIcon,
  CursorArrowIcon,
  BlendingModeIcon
} from '@radix-ui/react-icons';
import '../css/PlateToolbar.css';

const PlateToolbar = () => (
  <Toolbar.Root className="ToolbarRoot" aria-label="Formatting options" orientation="vertical">
    <Toolbar.ToggleGroup type="single" aria-label="Text formatting">
    <Toolbar.ToggleItem
        className="ToolbarToggleItem"
        value="select range"
        aria-label="Select Range"
      >
        <CursorArrowIcon />
      </Toolbar.ToggleItem>
      <Toolbar.ToggleItem className="ToolbarToggleItem" value="move" aria-label="Move">
        <MoveIcon />
      </Toolbar.ToggleItem>
      <Toolbar.ToggleItem className="ToolbarToggleItem" value="select" aria-label="Select">
        <GroupIcon />
      </Toolbar.ToggleItem>
      <Toolbar.ToggleItem className="ToolbarToggleItem" value="colors" aria-label="Colors">
        <BlendingModeIcon />
      </Toolbar.ToggleItem>
    </Toolbar.ToggleGroup>
    <Toolbar.Separator className="ToolbarSeparator" />
    <Toolbar.ToggleGroup type="single" defaultValue="center" aria-label="Text alignment">
      <Toolbar.ToggleItem className="ToolbarToggleItem" value="left" aria-label="Left aligned">
        <TextAlignLeftIcon />
      </Toolbar.ToggleItem>
      <Toolbar.ToggleItem className="ToolbarToggleItem" value="center" aria-label="Center aligned">
        <TextAlignCenterIcon />
      </Toolbar.ToggleItem>
      <Toolbar.ToggleItem className="ToolbarToggleItem" value="right" aria-label="Right aligned">
        <TextAlignRightIcon />
      </Toolbar.ToggleItem>
    </Toolbar.ToggleGroup>
  </Toolbar.Root>
);

export default PlateToolbar;