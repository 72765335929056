import './App.css';
import React from 'react';
import MainMenu from './lib/MainMenu.js';
import PlateToolbar from './lib/PlateToolbar';
import UIPlateMapTemplate from './ui/UIPlateMapTemplate';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <MainMenu />
      </header>
      <div className="App-body">
        <PlateToolbar />
        <UIPlateMapTemplate />
      </div>
    </div>
  );
}

export default App;
