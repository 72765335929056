import React, {Component} from 'react';

export default class UIWell extends Component {
    constructor(props) {
      super(props);
      this.well = this.props.well;
      this.state = {
        selected: props.selected || false
      };
    }

    toggleClass() {
      if(this.well) {
        const currentState = this.state.selected;
        const newState = !currentState;
        this.setState({ selected: newState });
        this.well.toggleFlag('skip');
        console.log('toggled skip to', this.well);
      }
    };

    render() {
      let cssClass = [this.props.cssClass];
      let id = 'none';
      if(this.well) {
        id = this.well.id;
      }
      cssClass.push(this.state.selected ? 'selected': '');

      return (
        <div 
            className={cssClass.join(' ')} 
            onClick={this.props.onClick || this.toggleClass.bind(this)} 
            style={this.props.wellStyle}
            data-id={id}
        >
          <p>{this.state.selected ? '' : this.props.name}</p>
        </div>
      );
    }
  }