import Plate from './Plate.js'
import Alphabet from './Alphabet.js'

/*
 * What's the difference between an existing plate set with things already set,
 * and a new plate set where new positions and contents can be added?
 */
export default class PlateSet {
  constructor({ plates = [], options = {newPlateCols: 12, newPlateRows: 8} }) {
    this.plates = PlateSet.toArray(plates);
    this.options = options;
    this.skippedWells = [];
  }

  size() {
    return this.plates.length;
  }

  addPlate(plate) {
    this.plates.push(plate);
  }

  getPlate(plateIndex) {
    return this.plates[plateIndex];
  }

  createPlate() {
    let plate = new Plate(
      [],
      {
        rows: this.options.newPlateRows,
        cols: this.options.newPlateCols,
        rowLabels: Alphabet.slice(0, 7),
        colLabels: ['1','2','3','4','5','6','7','8','9','10','11','12']
      }
    )

    let plateNum = this.size();
    console.log('createPlate', plateNum);
    console.log('this.skippedWells::', this.skippedWells);
    // Hack to handle skipping wells in the dest plate set

    let indexOffset = plateNum * (this.options.newPlateRows * this.options.newPlateCols);
    let indexInPlate = 0;
    plate.each((well) => {
      let i = indexOffset + indexInPlate;
      if(i < this.skippedWells.length) {
        let skip = this.skippedWells[i];
        if(skip) {
          well.flag('skip', skip);
        }
      }
      indexInPlate++;
    });
    // End Hack

    this.plates.push(plate);
  }

  selectColumn(colIndex) {
    this.plates.forEach((plate) => {
      plate.each((well) => {
        if(well.colIndex === colIndex) {
          well.toggleFlag('skip');
        }
      });
    });
  }

  selectRow(rowIndex) {
    this.plates.forEach((plate) => {
      plate.each((well) => {
        if(well.rowIndex === rowIndex) {
          well.toggleFlag('skip');
        }
      });
    });
  }

  toggleAll(rowIndex) {
    this.plates.forEach((plate) => {
      plate.each((well) => {
        well.toggleFlag('skip');
      });
    });
  }

  each(func) {
    for(let i = 0; i < this.plates.lenth; i++) {
      func.call(this, this.plates[i]);
    }
  }

  numWells() {
    return (this.plates.length + 1) * (this.options.newPlateRows * this.options.newPlateCols);
  }

  map(callbackFunction) {
    let result = [];
    let length = this.plates.length;
    for (let i = 0; i < length; i++) {
      let counter = callbackFunction(this.plates[i]);
      result.push(counter);
    }
    return result;
  }

  resetPlates() {

    // Hack to track skipped wells since the destination plate wells are deleted and recreated
    this.skippedWells = [];
    let i = 0;
    this.plates.forEach(plate => {
      console.log(plate.name);
      console.log('UPDATE SKIPPING for a plate')
      plate.each((well) => {
        this.skippedWells[i] = well.hasFlag('skip');
        i++;
      });
    });
    console.log('UPDATE SKIPPING complete for ', i);
    console.log('this.skippedWells in reset', this.skippedWells);
    // End Hack

    this.plates = [];
    this.createPlate();
  }

  print() {
    this.plates.forEach(plate => {
      plate.print();
      console.log("======================");
    });
  }

  // Return x if it is an array
  // Otherwise make it into an array with one element.
  static toArray(x) {
    if (Array.isArray(x) && x.length) {
      return x;
    }
    else {
      return [x];
    }
  }
}
