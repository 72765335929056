import { createSlice } from '@reduxjs/toolkit'

export const WellEventHandler = createSlice({
  name: 'counter',
  initialState: {
    row: 0,
    col: 0,
    contents: [],
    selected: false,
    attributes: {}
  },
  reducers: {
    select: (state) => {
      state.selected = true;
    },
    deselect: (state) => {
      state.selected = true;
    },
    toggle: (state) => {
      state.selected = !state.selected;
    },
    addContent: (state, action) => {
      state.contents += action.payload;
    },
    removeContent: (state, action) => {
      const index = state.contents.indexOf(action.payload);
      const removedItem = state.contents.splice(index, 1);
    },
    setAttribute: (state, action) => {
      const key = action.payload.name;
      const value = action.payload.value;
      state.attributes[key] = value;
    }
  },
})

export const { select, deselect, toggle, addContent, removeContent, setAttribute } = WellEventHandler.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.counter.value

export default WellEventHandler.reducer