import { configureStore } from '@reduxjs/toolkit'
import WellEventHandler from '../features/plates/WellEventHandler'

export default configureStore({
  reducer: {
      // plateSets: plateSetEventHandlers,
      // plates: platesEventHandlers,
      wells: WellEventHandler //,
      // menubar: menubarEventHandler,
      // toolbar: toolbarEventHandler
    }
});